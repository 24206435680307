import React from 'react';
import {Divider} from "@mui/material";
import {AppStoreLinks} from "@atttomyx/react-components";
import {MilesoftApp, Screenshots} from "../../components";
import {WORDS_APP_ID_APPLE, WORDS_APP_ID_GOOGLE, WORDS_NAME, WORDS_TAG_LINE} from "../../constants";
import "./words.css";
import {words as icon, words1, words2, words3, words4, words5} from "../../images/index";

const Words = (props) => {
    const { inline } = props;

    return <div className="words">
        {!inline ? <>
            <MilesoftApp
                icon={icon}
                name={WORDS_NAME}
                tagLine={WORDS_TAG_LINE}
            />
            <AppStoreLinks
                appleId={WORDS_APP_ID_APPLE}
                googleId={WORDS_APP_ID_GOOGLE}
            />
            <Divider/>
        </> : null}
        <Screenshots
            images={[words1, words2, words3, words4, words5]}
        />
    </div>
}

export default Words;
