import React from 'react';
import "./screenshots.css";

const Screenshots = (props) => {
    const { images } = props;
    let i = 0;

    return <div className="screenshots cards">
        {images.map(image => <div key={"image" + i++} className="card">
            <img src={image} className="screenshot" alt=""/>
        </div>)}
    </div>
}

export default Screenshots;
