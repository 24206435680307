import React from 'react';
import {Button, Divider, Link, Typography} from "@mui/material";
import {
    EmojiPeople as VolunteerIcon,
    PhoneIphone as PhoneIcon,
    Schedule as ScheduleIcon,
    SwapHoriz as TradeIcon
} from "@mui/icons-material";
import {AppStoreLinks} from "@atttomyx/react-components";
import {MilesoftApp, PointOfInterest, WatchDemoButton} from "../../components";
import {communication} from "@atttomyx/react-utils";
import {windowUtils} from "../../utils";
import {milesoft} from "@atttomyx/shared-constants";
import {
    SCHEDULE_APP_ID_APPLE,
    SCHEDULE_APP_ID_GOOGLE,
    SCHEDULE_NAME,
    SCHEDULE_SALES_EMAIL_BODY,
    SCHEDULE_SALES_EMAIL_SUBJECT,
    SCHEDULE_TAG_LINE
} from "../../constants";
import "./schedule.css";
import {schedule as icon} from "../../images/index";

const Schedule = (props) => {
    const { wide } = props;

    return <div className="schedule">
        <MilesoftApp
            icon={icon}
            name={SCHEDULE_NAME}
            tagLine={SCHEDULE_TAG_LINE}
        />
        <div className="cards points-of-interest">
            <PointOfInterest icon={<PhoneIcon color="primary"/>}
                             title="Paperless Power"
                             description="Screenshots keeps everyone informed in realtime on their phone"
            />
            <PointOfInterest icon={<ScheduleIcon color="primary"/>}
                             title="Time Saver"
                             description="Screenshots makes it easy to automate complex schedules"
            />
            <PointOfInterest icon={<VolunteerIcon color="primary"/>}
                             title="Flexible Coverage"
                             description="Screenshots allows you to easily request volunteers"
            />
            <PointOfInterest icon={<TradeIcon color="primary"/>}
                             title="Empower Employees"
                             description="Screenshots allows employees to trade shifts, and notifies you"
            />
        </div>
        <div className="action">
            <Button color="secondary" size="large"
                    onClick={() => communication.popEmail(milesoft.EMAIL_SALES, SCHEDULE_SALES_EMAIL_SUBJECT, SCHEDULE_SALES_EMAIL_BODY)}>
                Get started
            </Button>
        </div>
        <div className="action">
            <WatchDemoButton wide={wide}/>
        </div>
        <Divider/>
        <Typography variant="body1">
            Already have an account? <Link onClick={windowUtils.openSchedule}>Login here</Link>
        </Typography>
        <AppStoreLinks
            appleId={SCHEDULE_APP_ID_APPLE}
            googleId={SCHEDULE_APP_ID_GOOGLE}
        />
    </div>
}

export default Schedule;
