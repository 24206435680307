import React from 'react';
import ReactDOM from "react-dom";
import {Wrapper} from "@atttomyx/react-components";
import App from "./app";
import {branding} from "@atttomyx/react-utils";
import {themes} from "@atttomyx/shared-constants";

ReactDOM.render(
    <Wrapper theme={themes.LIGHT}
             branding={branding.BRANDING_LIGHT}
    >
        {(onThemeChange, snackbar, dimensions) =>
            <App
                onThemeChange={onThemeChange}
                snackbar={snackbar}
                dimensions={dimensions}
            />}
    </Wrapper>,
    document.getElementById('root')
);
