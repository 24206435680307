import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {List, ListItem, Typography} from "@mui/material";
import {EmailAddress} from "@atttomyx/react-components";
import {milesoft} from "@atttomyx/shared-constants";
import "./acceptableUsePolicy.css";

const AcceptableUsePolicy = () => {

    return <div className="aup">
        <Typography variant="h4" paragraph={true}>
            Acceptable Use Policy
        </Typography>
        <Typography paragraph={true}>
            This Acceptable Use Policy outlines what is acceptable conduct and what is unacceptable conduct for our Services. If we believe a violation of the policy is deliberate, repeated or presents a credible risk of harm to other users, our customers, the Services or any third parties, we may suspend or terminate your access. Capitalized terms used below, but not defined in this policy, have the meaning set forth in the <Link to="/user-terms-of-service">User Terms of Service</Link>.
        </Typography>
        <Typography variant="h5" paragraph={true}>
            Do:
        </Typography>
        <List dense={true}>
            <ListItem>
                comply with all User Terms of Service, including the terms of this Acceptable Use Policy;
            </ListItem>
            <ListItem>
                comply with all applicable laws and governmental regulations, including, but not limited to, all intellectual property, data, privacy, and export control laws, and regulations promulgated by any government agencies, including, but not limited to, the U.S. Securities and Exchange Commission, and any rules of any national and other securities exchanges;
            </ListItem>
            <ListItem>
                upload and disseminate only Customer Data to which Customer owns all required rights under law and under contractual and fiduciary relationships (such as proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements) and do so only in a manner consistent with applicable law;
            </ListItem>
            <ListItem>
                use commercially reasonable efforts to prevent unauthorized access to or use of the Services;
            </ListItem>
            <ListItem>
                keep passwords and all other login information confidential;
            </ListItem>
            <ListItem>
                monitor and control all activity conducted through your account in connection with the Services;
            </ListItem>
            <ListItem>
                promptly notify us if you become aware of or reasonably suspect any illegal or unauthorized activity or a security breach involving your accounts, including any loss, theft, or unauthorized disclosure or use of a username, password, or account; and
            </ListItem>
            <ListItem>
                comply in all respects with all applicable terms of the third party applications, including any that Customer elects to integrate with the Services that you access or subscribe to in connection with the Services.
            </ListItem>
        </List>
        <Typography variant="h5" paragraph={true}>
            Do Not:
        </Typography>
        <List dense={true}>
            <ListItem>
                permit any third party that is not an Authorized User to access or use a username or password for the Services;
            </ListItem>
            <ListItem>
                share, transfer or otherwise provide access to an account designated for you to another person;
            </ListItem>
            <ListItem>
                use the Services to store or transmit any Customer Data that may infringe upon or misappropriate someone else's trademark, copyright, or other intellectual property, or that may be tortious or unlawful;
            </ListItem>
            <ListItem>
                upload to, or transmit from, the Services any data, file, software, or link that contains or redirects to a virus, Trojan horse, worm, or other harmful component or a technology that unlawfully accesses or downloads content or information stored within the Services or on the hardware of {milesoft.COMPANY_NAME} or any third party;
            </ListItem>
            <ListItem>
                attempt to reverse engineer, decompile, hack, disable, interfere with, disassemble, modify, copy, translate, or disrupt the features, functionality, integrity, or performance of the Services (including any mechanism used to restrict or control the functionality of the Services), any third party use of the Services, or any third party data contained therein (except to the extent such restrictions are prohibited by applicable law);
            </ListItem>
            <ListItem>
                attempt to gain unauthorized access to the Services or related systems or networks or to defeat, avoid, bypass, remove, deactivate, or otherwise circumvent any software protection or monitoring mechanisms of the Services;
            </ListItem>
            <ListItem>
                access the Services in order to build a similar or competitive product or service or copy any ideas, features, functions, or graphics of the Services;
            </ListItem>
            <ListItem>
                use the Services in any manner that may harm minors;
            </ListItem>
            <ListItem>
                engage in activity that incites or encourages violence or hatred against individuals or groups;
            </ListItem>
            <ListItem>
                impersonate any person or entity, including, but not limited to, an employee of ours, an “Administrator”, an “Owner”, or any other Authorized User, or falsely state or otherwise misrepresent your affiliation with a person, organization or entity;
            </ListItem>
            <ListItem>
                use the Services to provide material support or resources (or to conceal or disguise the nature, location, source, or ownership of material support or resources) to any organization(s) designated by the United States government as a foreign terrorist organization pursuant to section 219 of the Immigration and Nationality Act or other laws and regulations concerning national security, defense or terrorism;
            </ListItem>
            <ListItem>
                access, search, or create accounts for the Services by any means other than our publicly supported interfaces (for example, "scraping" or creating accounts in bulk);
            </ListItem>
            <ListItem>
                send unsolicited communications, promotions or advertisements, or spam;
            </ListItem>
            <ListItem>
                send altered, deceptive or false source-identifying information, including "spoofing" or "phishing";
            </ListItem>
            <ListItem>
                sublicense, resell, time share or similarly exploit the Services;
            </ListItem>
            <ListItem>
                access or use the Services on behalf of, or for the benefit of, any Patent Assertion Entity (as may be defined in the Customer-Specific Supplement);
            </ListItem>
            <ListItem>
                use contact or other user information obtained from the Services (including email addresses) to contact Authorized Users outside of the Services without their express permission or authority or to create or distribute mailing lists or other collections of contact or user profile information for Authorized Users for use outside of the Services; or
            </ListItem>
            <ListItem>
                authorize, permit, enable, induce or encourage any third party to do any of the above.
            </ListItem>
        </List>
        <Typography paragraph={true}>
            Contacting {milesoft.COMPANY_NAME}
        </Typography>
        <Typography paragraph={true}>
            Please feel free to contact us if you have any questions regarding {milesoft.COMPANY_NAME}’s Acceptable Use Policy. You may contact us at <EmailAddress email={milesoft.EMAIL_LEGAL}/>.
        </Typography>
    </div>
}

export default withRouter(AcceptableUsePolicy);
