import React from 'react';
import {List, ListItem, Typography} from "@mui/material";
import {EmailAddress} from "@atttomyx/react-components";
import {milesoft} from "@atttomyx/shared-constants";
import "./privacyPolicy.css";

const PrivacyPolicy = () => {
    return <div className="privacy">
        <Typography variant="h4" paragraph={true}>
            Privacy Policy
        </Typography>
        <Typography paragraph={true}>
            This Privacy Policy describes how {milesoft.COMPANY_NAME} collects, uses and discloses information, and what choices you have with respect to the information.
        </Typography>
        <Typography paragraph={true}>
            When we refer to “{milesoft.COMPANY_NAME}”, we mean the {milesoft.COMPANY_NAME} entity that acts as the controller or processor of your information, as explained in more detail in the “Identifying the Data Controller and Processor” section below.
        </Typography>
        <Typography variant="h4" paragraph={true}>
            Applicability Of This Privacy Policy
        </Typography>
        <Typography paragraph={true}>
            This Privacy Policy applies to {milesoft.COMPANY_NAME}’s online apps, games, and platform, including the associated {milesoft.COMPANY_NAME} mobile and desktop applications (collectively, the “Services”), milesoft.io and other {milesoft.COMPANY_NAME} websites (collectively, the “Websites”) and other interactions (e.g., customer service inquiries, etc.) you may have with {milesoft.COMPANY_NAME}. If you do not agree with the terms, do not access or use the Services, Websites or any other aspect of {milesoft.COMPANY_NAME}’s business.
        </Typography>
        <Typography paragraph={true}>
            This Privacy Policy does not apply to any third party applications or software that integrate with the Services through the {milesoft.COMPANY_NAME} platform (“Third Party Services”), or any other third party products, services or businesses. In addition, a separate agreement governs delivery, access and use of the Services (the “Customer Agreement”), including the processing of any messages, files or other content submitted through Services accounts (collectively, “Customer Data”). The organization (e.g., your employer or another entity or person) that entered into the Customer Agreement (“Customer”) controls its use of the Services (its “Account”) and any associated Customer Data. If you have any questions about specific Account settings and privacy practices, please contact the Customer whose Account you use.
        </Typography>
        <Typography variant="h4" paragraph={true}>
            Information We Collect And Receive
        </Typography>
        <Typography paragraph={true}>
            {milesoft.COMPANY_NAME} may collect and receive Customer Data and other information and data (“Other Information”) in a variety of ways:
        </Typography>
        <List dense={true} className="i">
            <ListItem>
                Customer Data. Customers or individuals granted access to an Account by a Customer (“Authorized Users”) routinely submit Customer Data to {milesoft.COMPANY_NAME} when using the Services.
            </ListItem>
            <ListItem>
                Other Information. {milesoft.COMPANY_NAME} also collects, generates and/or receives Other Information:
                <List dense={true} className="ii">
                    <ListItem>
                        Account Information. To create or update an Account, you or your Customer (e.g., your employer) supply {milesoft.COMPANY_NAME} with an email address, phone number, password, domain and/or similar details. In addition, Customers that purchase a paid version of the Services provide {milesoft.COMPANY_NAME} (or its payment processors) with billing details such as credit card information, banking information and/or a billing address.
                    </ListItem>
                    <ListItem>
                        Usage Information.
                        <List dense={true} className="iii">
                            <ListItem>
                                Services Metadata. When an Authorized User interacts with the Services, metadata is generated that provides additional context about the way Authorized Users work. For example, {milesoft.COMPANY_NAME} logs the content and links you view or interact with, and what Third Party Services are used (if any).
                            </ListItem>
                            <ListItem>
                                Log data. As with most websites and technology services delivered over the Internet, our servers automatically collect information when you access or use our Websites or Services and record it in log files. This log data may include the Internet Protocol (IP) address, the address of the web page visited before using the Website or Services, browser type and settings, the date and time the Services were used, information about browser configuration and plugins, language preferences and cookie data.
                            </ListItem>
                            <ListItem>
                                Device information. {milesoft.COMPANY_NAME} collects information about devices accessing the Services, including type of device, what operating system is used, device settings, application IDs, unique device identifiers and crash data. Whether we collect some or all of this Other Information often depends on the type of device used and its settings.
                            </ListItem>
                            <ListItem>
                                Location information. We receive information from you, your Customer and other third-parties that helps us approximate your location. We may, for example, use a business address submitted by your employer, or an IP address received from your browser or device to determine approximate location. {milesoft.COMPANY_NAME} may also collect location information from devices in accordance with the consent process provided by your device.
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        Cookie Information. {milesoft.COMPANY_NAME} uses cookies and similar technologies in our Websites and Services to help us collect Other Information. The Websites and Services may also include cookies and similar tracking technologies of third parties, which may collect Other Information about you via the Websites and Services and across other websites and online services.
                    </ListItem>
                    <ListItem>
                        Third Party Services. A Customer can choose to permit or restrict Third Party Services for its Workspace. Typically, Third Party Services are software that integrate with our Services, and a Customer can permit its Authorized Users to enable and disable these integrations for its Workspace. {milesoft.COMPANY_NAME} may also develop and offer {milesoft.COMPANY_NAME} applications that connect the Services with a Third Party Service. Once enabled, the provider of a Third Party Service may share certain information with {milesoft.COMPANY_NAME}. Authorized Users should check the privacy settings and notices in these Third Party Services to understand what data may be disclosed to {milesoft.COMPANY_NAME}. When a Third Party Service is enabled, {milesoft.COMPANY_NAME} is authorized to connect and access Other Information made available to {milesoft.COMPANY_NAME} in accordance with our agreement with the Third Party Provider and any permission(s) granted by Customer (including, by its Authorized User(s)). We do not, however, receive or store passwords for any of these Third Party Services when connecting them to the Services.
                    </ListItem>
                    <ListItem>
                        Additional Information Provided to {milesoft.COMPANY_NAME}. We also receive Other Information when submitted to our Websites or in other ways, such as if you request support, interact with our social media accounts, or otherwise communicate with {milesoft.COMPANY_NAME}.
                    </ListItem>
                </List>
            </ListItem>
        </List>
        <Typography paragraph={true}>
            Generally, no one is under any statutory or contractual obligation to provide any Customer Data or Other Information (collectively, “Information”). However, certain Information is collected automatically, and if some Information, such as Account setup details, is not provided, we may be unable to provide the Services.
        </Typography>
        <Typography variant="h4" paragraph={true}>
            How We Use Information
        </Typography>
        <Typography paragraph={true}>
            Customer Data will be used by {milesoft.COMPANY_NAME} in accordance with Customer’s instructions, including any applicable terms in the Customer Agreement and Customer’s use of Services functionality, and as required by applicable law. {milesoft.COMPANY_NAME} is a processor of Customer Data and Customer is the controller. Customer may, for example, use the Services to grant and remove access to an Account, assign roles and configure settings, access, modify, export, share and remove Customer Data and otherwise apply its policies to the Services.
        </Typography>
        <Typography paragraph={true}>
            {milesoft.COMPANY_NAME} uses Other Information in furtherance of our legitimate interests in operating our Services, Websites and business. More specifically, {milesoft.COMPANY_NAME} uses Other Information:
        </Typography>
        <List dense={true} className="i">
            <ListItem>
                To provide, update, maintain and protect our Services, Websites and business. This includes use of Other Information to support delivery of the Services under a Customer Agreement, prevent or address service errors, security or technical issues, analyze and monitor usage, trends and other activities, or at an Authorized User’s request.
            </ListItem>
            <ListItem>
                As required by applicable law, legal process or regulation.
            </ListItem>
            <ListItem>
                To communicate with you by responding to your requests, comments and questions. If you contact us, we may use your Other Information to respond.
            </ListItem>
            <ListItem>
                To develop and provide search, learning and productivity tools and additional features. {milesoft.COMPANY_NAME} tries to make the Services as useful as possible for specific Accounts and Authorized Users. For example, we may make Services or Third Party Service suggestions based on historical use and predictive models, and identify organizational trends & insights, to customize a Services experience, or create new features and products.
            </ListItem>
            <ListItem>
                To send emails and other communications. We may send you service, technical and other administrative emails, messages, and other types of communications. We may also contact you to inform you about changes in our Services, our Services offerings, and important Services-related notices, such as security and fraud notices. These communications are considered part of the Services and you may not opt out of them. In addition, we sometimes send emails about new product features, promotional communications or other news about {milesoft.COMPANY_NAME}. These are marketing messages, so you can control whether or not you receive them. If you have additional questions about a message you have received from {milesoft.COMPANY_NAME} please reach out through the contact mechanisms described below.
            </ListItem>
            <ListItem>
                For billing, account management and other administrative matters. {milesoft.COMPANY_NAME} may need to contact you for invoicing, account management, and similar reasons and we use account data to administer accounts and keep track of billing and payments.
            </ListItem>
            <ListItem>
                To investigate and help prevent security issues and abuse.
            </ListItem>
        </List>
        <Typography paragraph={true}>
            If Information is aggregated or de-identified so that it is no longer reasonably associated with an identified or identifiable natural person, {milesoft.COMPANY_NAME} may use it for any business purpose. To the extent Information is associated with an identified or identifiable natural person and is protected as personal data under applicable data protection law, it is referred to in this Privacy Policy as “Personal Data.”
        </Typography>
        <Typography variant="h4" paragraph={true}>
            Data Retention
        </Typography>
        <Typography paragraph={true}>
            {milesoft.COMPANY_NAME} will retain Customer Data in accordance with a Customer’s instructions, including any applicable terms in the Customer Agreement and Customer’s use of Services functionality, and as required by applicable law. Depending on the Services plan, Customer may be able to customize its retention settings and apply those customized settings at the Account level. The deletion of Customer Data and other use of the Services by Customer may result in the deletion and/or de-identification of certain associated Other Information. {milesoft.COMPANY_NAME} may retain Other Information pertaining to you for as long as necessary for the purposes described in this Privacy Policy. This may include keeping your Other Information after you have deactivated your account for the period of time needed for {milesoft.COMPANY_NAME} to pursue legitimate business interests, conduct audits, comply with (and demonstrate compliance with) legal obligations, resolve disputes, and enforce our agreements.
        </Typography>
        <Typography variant="h4" paragraph={true}>
            How We Share And Disclose Information
        </Typography>
        <Typography paragraph={true}>
            This section describes how {milesoft.COMPANY_NAME} may share and disclose Information. Customers determine their own policies and practices for the sharing and disclosure of Information, and {milesoft.COMPANY_NAME} does not control how they or any other third parties choose to share or disclose Information.
        </Typography>
        <List dense={true} className="i">
            <ListItem>
                Customer’s Instructions. {milesoft.COMPANY_NAME} will solely share and disclose Customer Data in accordance with a Customer’s instructions, including any applicable terms in the Customer Agreement and Customer’s use of Services functionality, and in compliance with applicable law and legal process.
            </ListItem>
            <ListItem>
                Displaying the Services. When an Authorized User submits Other Information, it may be displayed to other Authorized Users in the same or connected Accounts. For example, an Authorized User’s picture may be displayed with their Account profile.
            </ListItem>
            <ListItem>
                Collaborating with Others. The Services provide different ways for Authorized Users working in independent Accounts to collaborate, such as in-app messages, notifications, or email interoperability.
            </ListItem>
            <ListItem>
                Customer Access. Owners, administrators, Authorized Users, and other Customer representatives and personnel may be able to access, modify, or restrict access to Other Information. This may include, for example, your employer using Service features to access or modify your profile details.
            </ListItem>
            <ListItem>
                Third Party Service Providers and Partners. We may engage third party companies or individuals as service providers or business partners to process Other Information and support our business. These third parties may, for example, provide virtual computing and storage services, or we may share business information to develop strategic partnerships with Third Party Service providers to support our common customers.
            </ListItem>
            <ListItem>
                Third Party Services. Customer may enable or permit Authorized Users to enable Third Party Services. We require each Third Party Service to disclose all permissions for information access in the Services, but we do not guarantee that they do so. When enabled, {milesoft.COMPANY_NAME} may share Other Information with Third Party Services. Third Party Services are not owned or controlled by {milesoft.COMPANY_NAME} and third parties that have been granted access to Other Information may have their own policies and practices for its collection, use, and sharing. Please check the permissions, privacy settings, and notices for these Third Party Services or contact the provider for any questions.
            </ListItem>
            <ListItem>
                Corporate Affiliates. {milesoft.COMPANY_NAME} may share Other Information with its corporate affiliates, parents and/or subsidiaries.
            </ListItem>
            <ListItem>
                During a Change to {milesoft.COMPANY_NAME}’s Business. If {milesoft.COMPANY_NAME} engages in a merger, acquisition, bankruptcy, dissolution, reorganization, sale of some or all of {milesoft.COMPANY_NAME}’s assets or stock, financing, public offering of securities, acquisition of all or a portion of our business, a similar transaction or proceeding, or steps in contemplation of such activities, some or all Other Information may be shared or transferred, subject to standard confidentiality arrangements.
            </ListItem>
            <ListItem>
                Aggregated or De-identified Data. We may disclose or use aggregated or de-identified Other Information for any purpose. For example, we may share aggregated or de-identified Other Information with prospects or partners for business or research purposes, such as telling a prospective {milesoft.COMPANY_NAME} customer the average amount of time spent within a typical Account.
            </ListItem>
            <ListItem>
                To Comply with Laws. If we receive a request for information, we may disclose Other Information if we reasonably believe disclosure is in accordance with or required by any applicable law, regulation, or legal process.
            </ListItem>
            <ListItem>
                To enforce our rights, prevent fraud, and for safety. {milesoft.COMPANY_NAME} will act, under the law and as necessary, to protect and defend the rights, property, or safety of {milesoft.COMPANY_NAME} or third parties, including enforcing contracts or policies, or in connection with investigating and preventing fraud or security issues.
            </ListItem>
            <ListItem>
                With Consent. {milesoft.COMPANY_NAME} may share Other Information with third parties when we have consent to do so.
            </ListItem>
        </List>
        <Typography variant="h4" paragraph={true}>
            Security
        </Typography>
        <Typography paragraph={true}>
            {milesoft.COMPANY_NAME} takes security of data very seriously. {milesoft.COMPANY_NAME} works hard to protect Other Information you provide from loss, misuse, and unauthorized access or disclosure. These steps take into account the sensitivity of the Other Information we collect, process and store, and the current state of technology. Given the nature of communications and information processing technology, {milesoft.COMPANY_NAME} cannot guarantee that Information during transmission through the Internet or while stored on our systems or otherwise in our care will be absolutely safe from intrusion by others. When you click a link to a third-party site, you will be leaving our site and we don’t control or endorse what is on third-party sites.
        </Typography>
        <Typography variant="h4" paragraph={true}>
            Identifying The Data Controller And Processor
        </Typography>
        <Typography paragraph={true}>
            Data protection law in certain jurisdictions differentiates between the “controller” and “processor” of information. In general, Customer is the controller of Customer Data. In general, {milesoft.COMPANY_NAME} is the processor of Customer Data and the controller of Other Information.
        </Typography>
        <Typography variant="h4" paragraph={true}>
            Your Rights
        </Typography>
        <Typography paragraph={true}>
            Individuals located in certain countries, including the European Economic Area and the United Kingdom, have certain statutory rights in relation to their personal data. Subject to any exemptions provided by law, you may have the right to request access to Information, as well as to seek to update, delete or correct this Information. You can usually do this using the settings and tools provided in your Services account. If you cannot use the settings and tools, contact the Customer who controls your account for additional access and assistance.
        </Typography>
        <Typography paragraph={true}>
            To the extent that {milesoft.COMPANY_NAME}’s processing of your Personal Data is subject to the General Data Protection Regulation (or applicable laws covering the processing of Personal Data in the United Kingdom), {milesoft.COMPANY_NAME} relies on its legitimate interests, described above, to process your data. {milesoft.COMPANY_NAME} may also process Other Information that constitutes your Personal Data for direct marketing purposes, and you have a right to object to {milesoft.COMPANY_NAME}’s use of your Personal Data for this purpose at any time.
        </Typography>
        <Typography variant="h4" paragraph={true}>
            Contacting {milesoft.COMPANY_NAME}
        </Typography>
        <Typography paragraph={true}>
            Please feel free to contact {milesoft.COMPANY_NAME} if you have any questions regarding this Privacy Policy or {milesoft.COMPANY_NAME}’s practices, or if you are seeking to exercise any of your statutory rights. {milesoft.COMPANY_NAME} will respond within a reasonable timeframe. You may contact us at <EmailAddress email={milesoft.EMAIL_PRIVACY}/>.
        </Typography>
    </div>
}

export default PrivacyPolicy;
