import React from 'react';
import {Typography} from "@mui/material";
import "./pointOfInterest.css";

const PointOfInterest = (props) => {
    const { icon, title, description } = props;

    return <div className="card point-of-interest">
        {icon ? icon : null}
        <Typography variant="h5" className="title">
            {title}
        </Typography>
        <Typography variant="body1" className="description">
            {description}
        </Typography>
    </div>
}

export default PointOfInterest;
