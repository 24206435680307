import React from 'react';
import {Divider} from "@mui/material";
import {AppStoreLinks} from "@atttomyx/react-components";
import {MilesoftApp, Screenshots} from "../../components";
import {SUDOKU_APP_ID_APPLE, SUDOKU_APP_ID_GOOGLE, SUDOKU_NAME, SUDOKU_TAG_LINE} from "../../constants";
import "./sudoku.css";
import {sudoku as icon, sudoku1, sudoku2, sudoku3, sudoku4} from "../../images/index";

const Sudoku = (props) => {
    const { inline } = props;

    return <div className="sudoku">
        {!inline ? <>
            <MilesoftApp
                icon={icon}
                name={SUDOKU_NAME}
                tagLine={SUDOKU_TAG_LINE}
            />
            <AppStoreLinks
                appleId={SUDOKU_APP_ID_APPLE}
                googleId={SUDOKU_APP_ID_GOOGLE}
            />
            <Divider/>
        </> : null}
        <Screenshots
            images={[sudoku1, sudoku2, sudoku3, sudoku4]}
        />
    </div>
}

export default Sudoku;
