import React from 'react';
import {Link} from "react-router-dom";
import {Typography} from "@mui/material";
import {EmailAddress} from "@atttomyx/react-components";
import {milesoft} from "@atttomyx/shared-constants";
import "./userTermsOfService.css";

const UserTermsOfService = () => {

    return <div className="utos">
        <Typography variant="h4" paragraph={true}>
            User Terms of Service
        </Typography>
        <Typography paragraph={true}>
            These User Terms of Service (the “User Terms”) govern your access and use of our online apps, games, and platform (the “Services”). Please read them carefully. We are grateful that you’re here.
        </Typography>
        <Typography variant="h5" paragraph={true}>
            First Things First
        </Typography>
        <Typography paragraph={true}>
            These User Terms are Legally Binding
        </Typography>
        <Typography paragraph={true}>
            These User Terms are a legally binding contract between you and us. As part of these User Terms, you agree to comply with our <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>, which is incorporated by reference into these User Terms. If you access or use the Services you confirm that you have read, understand, and agree to be bound by the User Terms and the <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>. “We”, “our” and “us” currently refers to the applicable {milesoft.COMPANY_NAME} entity in the Contract (defined below).
        </Typography>
        <Typography variant="h5" paragraph={true}>
            Customer’s Choices and Instructions
        </Typography>
        <Typography paragraph={true}>
            You are an Authorized User on an Account Controlled by a “Customer”
        </Typography>
        <Typography paragraph={true}>
            An organization or other third party that we refer to in these User Terms as “Customer” has invited you to join an account (i.e., a unique domain where a group of users may access the Services). If you are joining one of your employer’s accounts, for example, Customer is your employer. If you are joining an account created by your friend using her personal email address to work on her new startup idea, she is our Customer and she is authorizing you to join her account.
        </Typography>
        <Typography paragraph={true}>
            What This Means for You—and for Us
        </Typography>
        <Typography paragraph={true}>
            Customer has separately agreed to our User Terms of Service or entered into a written agreement with us (in either case, the “Contract”) that permitted Customer to create and configure an account so that you and others could join (each invitee granted access to the Services, including you, is an “Authorized User”). The Contract contains our commitment to deliver the Services to Customer, who may then invite Authorized Users to join their account(s). When an Authorized User (including, you) submits content or information to the Services (“Customer Data”), you acknowledge and agree that the Customer Data is owned by Customer and the Contract provides Customer with many choices and control over that Customer Data. For example, Customer may provision or deprovision access to the Services, enable or disable third party integrations, manage permissions and settings, and these choices and instructions may result in the access, use, disclosure, modification or deletion of certain or all Customer Data.
        </Typography>
        <Typography paragraph={true}>
            The Relationship Between You, Customer and Us
        </Typography>
        <Typography paragraph={true}>
            AS BETWEEN US AND CUSTOMER, YOU AGREE THAT IT IS SOLELY CUSTOMER’S RESPONSIBILITY TO (A) INFORM YOU AND ANY AUTHORIZED USERS OF ANY RELEVANT CUSTOMER POLICIES AND PRACTICES AND ANY SETTINGS THAT MAY IMPACT THE PROCESSING OF CUSTOMER DATA; (B) OBTAIN ANY RIGHTS, PERMISSIONS OR CONSENTS FROM YOU AND ANY AUTHORIZED USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF CUSTOMER DATA AND THE OPERATION OF THE SERVICES; (C) ENSURE THAT THE TRANSFER AND PROCESSING OF CUSTOMER DATA UNDER THE CONTRACT IS LAWFUL; AND (D) RESPOND TO AND RESOLVE ANY DISPUTE WITH YOU AND ANY AUTHORIZED USER RELATING TO OR BASED ON CUSTOMER DATA, THE SERVICES OR CUSTOMER’S FAILURE TO FULFILL THESE OBLIGATIONS. {milesoft.COMPANY_NAME.toUpperCase()} MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, TO YOU RELATING TO THE SERVICES, WHICH ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS.
        </Typography>
        <Typography variant="h5" paragraph={true}>
            A Few Ground Rules
        </Typography>
        <Typography paragraph={true}>
            While You Are Here, You Must Follow the Rules
        </Typography>
        <Typography paragraph={true}>
            To help ensure a safe and productive work environment, all Authorized Users must comply with our <Link to="/acceptable-use-policy">Acceptable Use Policy</Link> and any applicable policies established by Customer. If you see inappropriate behavior or content, please report it to your Primary Owner or employer.
        </Typography>
        <Typography paragraph={true}>
            You Are Here At the Pleasure of Customer (and Us)
        </Typography>
        <Typography paragraph={true}>
            These User Terms remain effective until Customer’s subscription for you expires or terminates, or your access to the Services has been terminated by Customer or us. Please contact Customer if you at any time or for any reason wish to terminate your account.
        </Typography>
        <Typography variant="h5" paragraph={true}>
            Limitation of Liability
        </Typography>
        <Typography paragraph={true}>
            If we believe that there is a violation of the Contract, User Terms, the <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>, or any of our other policies that can simply be remedied by Customer’s removal of certain Customer Data or taking other action, we will, in most cases, ask Customer to take action rather than intervene. We may directly step in and take what we determine to be appropriate action (including disabling your access) if Customer does not take appropriate action or we believe there is a credible risk of harm to us, the Services, Authorized Users, or any third parties. IN NO EVENT WILL YOU OR WE HAVE ANY LIABILITY TO THE OTHER FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNLESS YOU ARE ALSO A CUSTOMER (AND WITHOUT LIMITATION TO OUR RIGHTS AND REMEDIES UNDER THE CONTRACT), YOU WILL HAVE NO FINANCIAL LIABILITY TO US FOR A BREACH OF THESE USER TERMS. THE FOREGOING DISCLAIMERS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW AND DO NOT LIMIT EITHER PARTY’S RIGHT TO SEEK AND OBTAIN EQUITABLE RELIEF.
        </Typography>
        <Typography paragraph={true}>
            Survival
        </Typography>
        <Typography paragraph={true}>
            The sections titled “The Relationship Between You, Customer, and Us,” “Limitation of Liability,” and “Survival,” and all of the provisions under the general heading “General Provisions” will survive any termination or expiration of the User Terms.
        </Typography>
        <Typography variant="h5" paragraph={true}>
            General Provisions
        </Typography>
        <Typography paragraph={true}>
            Email
        </Typography>
        <Typography paragraph={true}>
            Except as otherwise set forth herein, all notices under the User Terms will be by email. Notices to {milesoft.COMPANY_NAME} should be sent to <EmailAddress email={milesoft.EMAIL_LEGAL}/>. A notice will be deemed to have been duly given the day after it is sent. Notices under the Contract will be delivered solely to Customer in accordance with the terms of that agreement.
        </Typography>
        <Typography paragraph={true}>
            Privacy Policy
        </Typography>
        <Typography paragraph={true}>
            Please review our <Link to="/privacy-policy">Privacy Policy</Link> for more information regarding how we collect and use data relating to the use and performance of our products.
        </Typography>
        <Typography paragraph={true}>
            Waiver
        </Typography>
        <Typography paragraph={true}>
            No failure or delay by either party in exercising any right under the User Terms, including the <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>, will constitute a waiver of that right. No waiver under the User Terms will be effective unless made in writing and signed by an authorized representative of the party being deemed to have granted the waiver.
        </Typography>
        <Typography paragraph={true}>
            Severability
        </Typography>
        <Typography paragraph={true}>
            The User Terms, including the <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>, will be enforced to the fullest extent permitted under applicable law. If any provision of the User Terms is held by a court of competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the User Terms will remain in effect.
        </Typography>
        <Typography paragraph={true}>
            Assignment
        </Typography>
        <Typography paragraph={true}>
            You may not assign any of your rights or delegate your obligations under these User Terms, including the <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>, whether by operation of law or otherwise, without the prior written consent of us (not to be unreasonably withheld). We may assign these User Terms in their entirety (including all terms and conditions incorporated herein by reference), without your consent, to a corporate affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of our assets.
        </Typography>
        <Typography paragraph={true}>
            Governing Law; Venue; Fees
        </Typography>
        <Typography paragraph={true}>
            The User Terms, including the <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>, and any disputes arising out of or related hereto, will be governed exclusively by the same applicable governing law of the Contract, without regard to conflicts of laws rules or the United Nations Convention on the International Sale of Goods. The courts located in the applicable venue of the Contract will have exclusive jurisdiction to adjudicate any dispute arising out of or relating to the User Terms, including the <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>, or its formation, interpretation or enforcement. Each party hereby consents and submits to the exclusive jurisdiction of such courts. In any action or proceeding to enforce rights under the User Terms, the prevailing party will be entitled to recover its reasonable costs and attorney fees.
        </Typography>
        <Typography paragraph={true}>
            Entire Agreement
        </Typography>
        <Typography paragraph={true}>
            The User Terms, including any terms incorporated by reference into the User Terms, constitute the entire agreement between you and us and supersede all prior and contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter. To the extent of any conflict or inconsistency between the provisions in these User Terms and any pages referenced in these User Terms, the terms of these User Terms will first prevail; provided, however, that if there is a conflict or inconsistency between the Contract and the User Terms, the terms of the Contract will first prevail, followed by the provisions in these User Terms, and then followed by the pages referenced in these User Terms (e.g., the <Link to="/privacy-policy">Privacy Policy</Link>). Customer will be responsible for notifying Authorized Users of those conflicts or inconsistencies and until such notification occurs the terms set forth herein will be binding.
        </Typography>
        <Typography paragraph={true}>
            Contacting {milesoft.COMPANY_NAME}
        </Typography>
        <Typography paragraph={true}>
            Please feel free to contact us if you have any questions regarding {milesoft.COMPANY_NAME}’s User Terms of Service. You may contact us at <EmailAddress email={milesoft.EMAIL_LEGAL}/>.
        </Typography>
    </div>
}

export default UserTermsOfService;
