import React from "react";
import {withRouter} from "react-router-dom";
import {AppBar, Toolbar, Typography} from "@mui/material";
import {CompanyLogo} from "@atttomyx/react-components";
import {router} from "@atttomyx/react-utils";
import {PAGE_HOME} from "../../constants";
import "./header.css";

const Header = (props) => {
    const { history } = props;

    return <div className="header">
        <AppBar position="fixed" color="primary">
            <Toolbar className="items">
                <div className="left">
                    <Typography className="item first" component="div"
                                onClick={() => router.redirectTo(history, PAGE_HOME)}>
                        <CompanyLogo mode="icon-transparent" size="small"/>
                    </Typography>
                </div>
            </Toolbar>
        </AppBar>
    </div>
}

export default withRouter(Header);
