import React from "react";
import {makeStyles} from "@mui/styles";
import {EmbeddedYouTube} from "@atttomyx/react-components";

const useStyles = makeStyles(theme => ({
    demo: {
        margin: "-32px -18px -50px -18px",
        height: ({height}) => height,
        "& .embedded-youtube": {
            margin: 0,
            width: "100%",
            maxWidth: "none",
            height: ({height}) => height,
            "& iframe": {
                margin: 0,
                width: "100%",
                maxWidth: "none",
                height: ({height}) => height,
            }
        }
    },
}));

const Demo = (props) => {
    const { dimensions, match } = props;
    const { params } = match;
    const { videoId } = params;

    const classes = useStyles({
        height: dimensions.height - 67,
    });

    return <div className={classes.demo}>
        <EmbeddedYouTube videoId={videoId}/>
    </div>
}

export default Demo;
