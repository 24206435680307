import React, {useState} from 'react';
import {withRouter} from "react-router-dom";
import {Button, Dialog, DialogContent} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {ClosableDialogTitle, EmbeddedYouTube} from "@atttomyx/react-components";
import {milesoft} from "@atttomyx/shared-constants";
import {SCHEDULE_NAME} from "../../constants";

const useStyles = makeStyles(theme => ({
    paper: {
        "& .MuiDialogTitle-root": {
            backgroundColor: theme.palette.primary.main,
        },
        "& .MuiDialogContent-root": {
            marginTop: "20px",
        },
    },
    paperWide: {
        "& .MuiPaper-root": {
            maxWidth: "610px !important",
        },
    },
    paperNarrow: {
        "& .MuiPaper-root": {
            overflow: "hidden",
        },
        "& .MuiDialogContent-root": {
            padding: "0",
            marginTop: "-20px",
            marginBottom: "-28px",
        },
    },
}));

const VIDEO_ID = "sIryIL9a_4Y";

const DemoModal = props => {
    const { wide, onClose } = props;
    const classes = useStyles();

    return <Dialog open={true} className={[classes.paper, wide ? classes.paperWide : classes.paperNarrow]}
                   aria-labelledby="demo-dialog"
    >
        <ClosableDialogTitle onClose={onClose}>
            Demo - {milesoft.COMPANY_NAME} {SCHEDULE_NAME}
        </ClosableDialogTitle>
        <DialogContent>
            <div className="videos">
                <EmbeddedYouTube videoId={VIDEO_ID}/>
            </div>
        </DialogContent>
    </Dialog>
}

const WatchDemoButton = (props) => {
    const { wide, history } = props;
    const [showDemo, setShowDemo] = useState(false);

    return <div className="watch-demo-button">
        <Button color="primary" size="large"
                onClick={() => wide ? setShowDemo(true) : history.push("/demo/" + VIDEO_ID)}>
            Watch demo
        </Button>
        {showDemo
            ? <DemoModal wide={wide}
                         onClose={() => setShowDemo(false)}/>
            : null}
    </div>
}

export default withRouter(WatchDemoButton);
