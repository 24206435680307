import React from 'react';
import {Divider} from "@mui/material";
import {AppStoreLinks} from "@atttomyx/react-components";
import {MilesoftApp, PointOfInterest, Screenshots} from "../../components";
import {REVEAL_APP_ID_APPLE, REVEAL_APP_ID_GOOGLE, REVEAL_NAME, REVEAL_TAG_LINE} from "../../constants";
import "./reveal.css";
import {
    reveal as icon,
    reveal1,
    reveal2,
    reveal3,
    reveal4,
    reveal5,
    reveal6,
    reveal7,
    reveal8
} from "../../images/index";

const Reveal = (props) => {
    const { inline } = props;

    return <div className="reveal">
        {!inline ? <>
            <MilesoftApp
                icon={icon}
                name={REVEAL_NAME}
                tagLine={REVEAL_TAG_LINE}
            />
            <AppStoreLinks
                appleId={REVEAL_APP_ID_APPLE}
                googleId={REVEAL_APP_ID_GOOGLE}
            />
            <Divider className="top"/>
        </> : null}
        <div className="cards points-of-interest">
            <PointOfInterest
                title="How to play"
                description="Draw, doodle, or just clear away the paint to reveal beautiful images. Share your favorites with friends."
            />
            <PointOfInterest
                title="Relaxing"
                description="As you gently swipe your screen to unveil beautiful images, you can relieve your stress and sooth your mind."
            />
            <PointOfInterest
                title="Therapy"
                description={`As calming therapy, ${REVEAL_NAME} uses visual, textile and optional audio stimulation to manage anxiety and elevate your mood.`}
            />
            <PointOfInterest
                title="Hint"
                description='The next category is random. To choose a specific category, tap on the one you want prior to tapping the "Next" button.'
            />
        </div>
        <Divider/>
        <Screenshots
            images={[reveal1, reveal2, reveal3, reveal4, reveal5]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="Categories"
                description={`With a vast selection of image categories to choose from, ${REVEAL_NAME} has something for everyone.`}
            />
            <PointOfInterest
                title="Offline"
                description={`No internet? No worries. You'll still be able to access your own photos and the "Offline" category featuring the adventures of ${REVEAL_NAME}'s mascot bunny.`}
            />
            <PointOfInterest
                title="Hint"
                description="You may select all categories at once by tapping and holding on an unchecked category. Or de-select all categories at once by tapping and holding on a checked category."
            />
        </div>
        <Divider/>
        <Screenshots
            images={[reveal6, reveal7]}
        />
        <Divider/>
        <div className="cards points-of-interest">
            <PointOfInterest
                title="My photos"
                description={`When your device asks if ${REVEAL_NAME} may access all or just some of your photos, we recommend you choose "all". (If you choose only "some", your device will ask this question again every time you open ${REVEAL_NAME}). After choosing "all", you may limit which of your photos show up in ${REVEAL_NAME} by setting up an album on your device, and then selecting that album in ${REVEAL_NAME}'s settings.`}
            />
            <PointOfInterest
                title="My photos (Android)"
                description="You may need to download the Google Gallery app in order to set up a photo album."
            />
            <PointOfInterest
                title="Privacy"
                description='Your photos are only visible to you, on your device, unless you choose to share one or more of them by clicking on the "Share" button.'
            />
        </div>
        <Divider/>
        <Screenshots
            images={[reveal8]}
        />
    </div>
}

export default Reveal;
