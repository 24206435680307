import React from 'react';
import {Typography} from "@mui/material";
import {milesoft} from "@atttomyx/shared-constants";
import "./milesoftApp.css";

const MilesoftApp = (props) => {
    const { icon, name, tagLine } = props;

    return <div className="milesoft-app">
        <div>
            <img src={icon} className="icon" alt=""/>
        </div>
        <Typography variant="h4">
            {milesoft.COMPANY_NAME} {name}
        </Typography>
        <Typography variant="caption">
            {tagLine}
        </Typography>
    </div>
}

export default MilesoftApp;
